import logo from './media/lucas.png';
import online from './media/estado/online.png'
import offline from './media/estado/offline.png'
import afk from './media/estado/afk.png'
import dnd from './media/estado/dnd.png'
import tv from './media/estado/tv.png'

import './App.css';

function App() {

  const imagenes = [online, offline, afk, dnd, tv];
  const imagen = imagenes[Math.floor(Math.random() * imagenes.length)];

  return (

    <>
      <div className="App">

        <div className="info">

          <h1>Luc4s</h1>
          <a>Discord Developer & Web Developer</a>

        </div>
        <div className='my'>
          <img src={logo} className="img" alt="logo" />
          <img src={imagen} className='status' alt="online" />
        </div>

        <a className='dd'>WEB EN DESARROLLO</a>
      </div>
    </>
  );
}

export default App;
